import React from 'react';
import { PinkertonLocationsViewContext, TemplateProps } from '../types';
import { PathService } from '../services';
import { PinkertonLocationsView } from '../views';

const PinkertonLocations: React.FunctionComponent<TemplateProps<
  PinkertonLocationsViewContext
>> = ({ location, pageContext }) => {
  PathService.set(location.pathname);
  return (
    <PinkertonLocationsView
      page={JSON.parse(pageContext.page)}
      content={JSON.parse(pageContext.content)}
      languageSwitch={JSON.parse(pageContext.languageSwitch)}
    />
  );
};

export default PinkertonLocations;
